<template>

    <section>
        <header class="d-flex align-items-center justify-content-center mt-2rem" style="font-family: 'Arial', sans-serif !important; font-size: 20px;" >
            <b>{{ this.clinic.nome }}</b>
        </header>

        <div class="form container-fluid" >
            <div class="row justify-content-center" >

                <div class="col-12 col-md-8 col-lg-6 col-xl-4" v-if="fase === 0" >
                    <div class="card mt-2rem" >
                        <div class="card-header" >
                            <h4 class="ft-1rem mb-0" >Olá!</h4>
                        </div>
                        <div class="card-body" >
                            <p>Para começar, por favor, insira o número do seu CPF. Vamos consultar o seu cadastro.</p>
                            <img :src="'/img/login.png'" class="img-fluid mx-auto my-4 d-block img-login" style="height: 200px;" alt="Informe o seu CPF para continuar" />
                            <div class="form-row" >
                                <div class="form-group required col-md-12 col-12 mb-0" >
                                    <label for="scpf" >Por favor, informe o seu CPF:</label>
                                    <input v-model="scpf" id="scpf" class="form-control" type="text" placeholder="ex: 000.000.000-00"
                                           v-mask="'###.###.###-##'" @input="recuperarPaciente" :disabled="token" required />
                                </div>
                            </div>

                            <div class="carregamento" v-if="consultandoCPF" >
                                <div class="alert alert-info mt-4 mb-0" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando o seu cadastro...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-warning mt-4 mb-0" v-if="cpfExistente == false || carregadoCIEC == false" >
                                <i class="fas fa-exclamation-circle" ></i> Ops! Ocorreu um erro na leitura dos dados.<br>Por favor retorne e tente novamente.
                            </div>

                            <div class="alert alert-success mt-4 mb-0" v-if="cpfExistente == true && carregadoCIEC == true" >
                                <i class="fas fa-check-circle" ></i> Tudo certo!
                                Clique em Próximo para continuar.
                            </div>
                        </div>

                        <div class="card-footer" v-if="cpfExistente == true && carregadoCIEC == true" >
                            <div class="float-right" >
                                <button class="btn btn-success" @click="avancar" >
                                    Próximo
                                </button>
                            </div>
                        </div>

                        <div class="card-footer" v-if="cpfExistente == false || carregadoCIEC == false" >
                            <div class="float-right" >
                                <button class="btn btn-success" @click="inicio" >
                                    Retornar
                                </button>
                            </div>
                        </div>
                    </div>
                </div> <!-- end fase 0 -->

                <div class="col-12 col-md-8 col-lg-6 col-xl-4" v-if="fase === 1" >
                    <div class="card mt-2rem" >
                        <div class="card-body" >
                            <p><b>Qual formulário deseja preencher agora:</b></p>
                            <div class="form-row" >
                                <div class="form-group required col-md-12 col-12 mb-0" >
                                    <label for="userOpt" >Por favor, selecione uma opção:</label>
                                    <select v-model="userOpt" id="userOpt" class="form-control" type="text" :disabled="token" required >
                                        <option value="anamnese" >Apenas anamnese</option>
                                        <option value="cadastro" >Apenas cadastro</option>
                                        <option value="completo" >Formulário completo</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer" v-if="userOpt == 'completo' || userOpt == 'cadastro' || userOpt == 'anamnese'" >
                            <div class="float-right" >
                                <button class="btn btn-success" @click="avancar" >
                                    Próximo
                                </button>
                            </div>
                        </div>
                    </div>
                </div> <!-- end fase 1 -->

                <div class="col-12" >
                    <form @submit.prevent="submitFormAnamnese" id="formAnamnese"
                        v-if="fase == 2 && userOpt == 'anamnese'"
                    >
                        <Anamnese   ref="vueFormAnamnese"   :anamnese="anamnese"       />
                        <Lgpd       ref="vueFormLgpd"       :slgpd="slgpd"             />
                        <Assinatura ref="vueFormAssinatura" @saveSig="salvaAssinatura" />

                        <div class="row" >
                            <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto" >
                                <button type="submit" class="btn btn-success btn-block mt-3" :disabled="gravando || this.assinatura == ''" >
                                    Confirmar meu cadastro
                                </button>
                            </div>
                        </div>
                        <div class="row" v-if="error == true" >
                            <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto" >
                                <div class="alert mt-3 mb-0" :class="messageStatus" >
                                    <i class="fas fa-exclamation-circle" ></i>
                                    {{ message }}
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="gravando" >
                            <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto" >
                                <div class="alert alert-info mt-3 mb-0" >
                                    <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                        <span class="sr-only" >Loading...</span>
                                    </div>
                                    Gravando seus dados...
                                </div>
                            </div>
                        </div>

                    </form>
                </div> <!-- end fase 2 && anamnese -->

                <div class="col-12" >
                    <form @submit.prevent="preSubmit" id="formCadastro"
                        v-if="fase == 2 && userOpt == 'cadastro'"
                    >
                        <Cadastro   ref="vueFormCadastro"
                            :url="url"
                            @updatesnome     ="updatesnome"     :snome     ="snome"
                            @updatessbrnome  ="updatessbrnome"  :ssbrnome  ="ssbrnome"
                            @updatedt_datnas ="updatedt_datnas" :dt_datnas ="dt_datnas"
                            @updatensexo     ="updatensexo"     :nsexo     ="nsexo"
                            @updatentpfone1  ="updatentpfone1"  :ntpfone1  ="ntpfone1"
                            @updatentpfone2  ="updatentpfone2"  :ntpfone2  ="ntpfone2"
                            @updatentpfone3  ="updatentpfone3"  :ntpfone3  ="ntpfone3"
                            @updatesfone1    ="updatesfone1"    :sfone1    ="sfone1"
                            @updatesfone2    ="updatesfone2"    :sfone2    ="sfone2"
                            @updatesfone3    ="updatesfone3"    :sfone3    ="sfone3"
                            @updatescpf      ="updatescpf"      :scpf      ="scpf"
                            @updatesrg       ="updatesrg"       :srg       ="srg"
                            @updatesend      ="updatesend"      :send      ="send"
                            @updatescomplem  ="updatescomplem"  :scomplem  ="scomplem"
                            @updatesbai      ="updatesbai"      :sbai      ="sbai"
                            @updatescid      ="updatescid"      :scid      ="scid"
                            @updatesuf       ="updatesuf"       :suf       ="suf"
                            @updatescep      ="updatescep"      :scep      ="scep"
                            @updatesemail    ="updatesemail"    :semail    ="semail"
                            @updatesprof     ="updatesprof"     :sprof     ="sprof"
                            @updatenconv     ="updatenconv"     :nconv     ="nconv"
                            @updatescart     ="updatescart"     :scart     ="scart"
                            @updatedt_datval ="updatedt_datval" :dt_datval ="dt_datval"
                            @updatentpind    ="updatentpind"    :ntpind    ="ntpind"
                            @updatesindpor   ="updatesindpor"   :sindpor   ="sindpor"
                            @updatenestciv   ="updatenestciv"   :nestciv   ="nestciv"
                            @updatenomres    ="updatenomres"    :nomres    ="nomres"
                            @updatescpfres   ="updatescpfres"   :scpfres   ="scpfres"
                            @updateslgpd     ="updateslgpd"     :slgpd     ="slgpd"
                            @updatenropac    ="updatenropac"    :nropac    ="nropac"

                            @updatelistaconvenios    ="updatelistaconvenios"    :listaconvenios    ="listaconvenios"
                            @updatetiposdeindicacao  ="updatetiposdeindicacao"  :tiposdeindicacao  ="tiposdeindicacao"
                            @updateestadocivil       ="updateestadocivil"       :estadocivil       ="estadocivil"
                            @updatelistaDeEstados    ="updatelistaDeEstados"    :listaDeEstados    ="listaDeEstados"
                            @updatelistaDeCidades    ="updatelistaDeCidades"    :listaDeCidades    ="listaDeCidades"
                            @updatecepExistente      ="updatecepExistente"      :cepExistente      ="cepExistente"
                        />
                        <Lgpd       ref="vueFormLgpd"       :slgpd="slgpd"             />
                        <Assinatura ref="vueFormAssinatura" @saveSig="salvaAssinatura" />

                        <div class="row" >
                            <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto" >
                                <button type="submit" class="btn btn-success btn-block mt-3" :disabled="gravando || this.assinatura == ''" >
                                    Confirmar meu cadastro
                                </button>
                            </div>
                        </div>
                        <div class="row" v-if="error == true" >
                            <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto" >
                                <div class="alert mt-3 mb-0" :class="messageStatus" >
                                    <i class="fas fa-exclamation-circle" ></i>
                                    {{ message }}
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="gravando" >
                            <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto" >
                                <div class="alert alert-info mt-3 mb-0" >
                                    <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                        <span class="sr-only" >Loading...</span>
                                    </div>
                                    Gravando seus dados...
                                </div>
                            </div>
                        </div>

                    </form>
                </div> <!-- end fase 2 && cadastro -->

                <div class="col-12" >
                    <form @submit.prevent="preSubmit" id="formCompleto"
                        v-if="fase == 2 && userOpt == 'completo'"
                    >
                        <Cadastro   ref="vueFormCadastro"
                            :url="url"
                            @updatesnome     ="updatesnome"     :snome     ="snome"
                            @updatessbrnome  ="updatessbrnome"  :ssbrnome  ="ssbrnome"
                            @updatedt_datnas ="updatedt_datnas" :dt_datnas ="dt_datnas"
                            @updatensexo     ="updatensexo"     :nsexo     ="nsexo"
                            @updatentpfone1  ="updatentpfone1"  :ntpfone1  ="ntpfone1"
                            @updatentpfone2  ="updatentpfone2"  :ntpfone2  ="ntpfone2"
                            @updatentpfone3  ="updatentpfone3"  :ntpfone3  ="ntpfone3"
                            @updatesfone1    ="updatesfone1"    :sfone1    ="sfone1"
                            @updatesfone2    ="updatesfone2"    :sfone2    ="sfone2"
                            @updatesfone3    ="updatesfone3"    :sfone3    ="sfone3"
                            @updatescpf      ="updatescpf"      :scpf      ="scpf"
                            @updatesrg       ="updatesrg"       :srg       ="srg"
                            @updatesend      ="updatesend"      :send      ="send"
                            @updatescomplem  ="updatescomplem"  :scomplem  ="scomplem"
                            @updatesbai      ="updatesbai"      :sbai      ="sbai"
                            @updatescid      ="updatescid"      :scid      ="scid"
                            @updatesuf       ="updatesuf"       :suf       ="suf"
                            @updatescep      ="updatescep"      :scep      ="scep"
                            @updatesemail    ="updatesemail"    :semail    ="semail"
                            @updatesprof     ="updatesprof"     :sprof     ="sprof"
                            @updatenconv     ="updatenconv"     :nconv     ="nconv"
                            @updatescart     ="updatescart"     :scart     ="scart"
                            @updatedt_datval ="updatedt_datval" :dt_datval ="dt_datval"
                            @updatentpind    ="updatentpind"    :ntpind    ="ntpind"
                            @updatesindpor   ="updatesindpor"   :sindpor   ="sindpor"
                            @updatenestciv   ="updatenestciv"   :nestciv   ="nestciv"
                            @updatenomres    ="updatenomres"    :nomres    ="nomres"
                            @updatescpfres   ="updatescpfres"   :scpfres   ="scpfres"
                            @updateslgpd     ="updateslgpd"     :slgpd     ="slgpd"
                            @updatenropac    ="updatenropac"    :nropac    ="nropac"

                            @updatelistaconvenios    ="updatelistaconvenios"    :listaconvenios    ="listaconvenios"
                            @updatetiposdeindicacao  ="updatetiposdeindicacao"  :tiposdeindicacao  ="tiposdeindicacao"
                            @updateestadocivil       ="updateestadocivil"       :estadocivil       ="estadocivil"
                            @updatelistaDeEstados    ="updatelistaDeEstados"    :listaDeEstados    ="listaDeEstados"
                            @updatelistaDeCidades    ="updatelistaDeCidades"    :listaDeCidades    ="listaDeCidades"
                            @updatecepExistente      ="updatecepExistente"      :cepExistente      ="cepExistente"
                        />
                        <Anamnese   ref="vueFormAnamnese"   :anamnese="anamnese"       />
                        <Lgpd       ref="vueFormLgpd"       :slgpd="slgpd"             />
                        <Assinatura ref="vueFormAssinatura" @saveSig="salvaAssinatura" />

                        <div class="row" >
                            <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto" >
                                <button type="submit" class="btn btn-success btn-block mt-3" :disabled="gravando || this.assinatura == ''" >
                                    Confirmar meu cadastro
                                </button>
                            </div>
                        </div>
                        <div class="row" v-if="error == true" >
                            <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto" >
                                <div class="alert mt-3 mb-0" :class="messageStatus" >
                                    <i class="fas fa-exclamation-circle" ></i>
                                    {{ message }}
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="gravando" >
                            <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto" >
                                <div class="alert alert-info mt-3 mb-0" >
                                    <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                        <span class="sr-only" >Loading...</span>
                                    </div>
                                    Gravando seus dados...
                                </div>
                            </div>
                        </div>

                    </form>
                </div> <!-- end fase 2 && completo -->

            </div> <!-- end row -->

            <div class="row justify-content-center" >
                <div class="col-12 col-lg-4" >
                    <div id="resultado" class="card mt-2rem" v-if="fase === 3" >
                        <div class="card-header" >
                            <h4 class="ft-1rem mb-0" >Obrigado, {{ snome }}!</h4>
                        </div>
                        <div class="card-body" >
                            <div class="alert mt-0 w-100" :class="messageStatus" >
                                {{ message }}
                            </div>
                            <button type="button" class="btn btn-success float-right" @click="inicio" >
                                Retornar
                            </button>
                        </div>
                    </div>
                </div>
            </div> <!-- end row -->
        </div>

        <div id="modal-img" class="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false" >
            <div class="modal-dialog modal-dialog-centered" >
                <div class="modal-content" >
                    <div class="modal-header" >
                        <h4 class="modal-title ft-1rem" >Foto</h4>
                    </div>
                    <div class="modal-body text-center" >
                        <p class="mb-0 " >Deseja continuar sem selecionar uma foto?</p>
                    </div>
                    <div class="modal-footer justify-content-center" >
                        <button type="button" class="btn btn-danger"  @click="subWithoutPhoto()" >Sim</button>
                        <button type="button" class="btn btn-info"    @click="openCamera()"      >Tirar uma</button>
                        <button type="button" class="btn btn-primary" @click="chooseImage()"     >Selecionar agora</button>
                    </div>
                </div>
            </div>
        </div>

    </section>

</template>

<script>
    import axios      from "axios";
    import moment     from "moment";

    import Anamnese   from "./partials/formAnamnese.vue";
    import Assinatura from "./partials/formAssinatura.vue";
    import Cadastro   from "./partials/formCadastro.vue";
    import Lgpd       from "./partials/formLgpd.vue";

    export default {
        data()
        {
            return {
                headers           : { headers : { "Content-Type" : "application/json", Accept : "*" } },
                userOpt           : null,
                anamnese          : [],

                imageUrl          : null,
                imageFile         : null,
                imageName         : "",

                fase              : 0,
                snome             : "",
                ssbrnome          : "",
                dt_datnas         : "",
                nsexo             : "",
                ntpfone1          : "",
                ntpfone2          : "",
                ntpfone3          : "",
                sfone1            : "",
                sfone2            : "",
                sfone3            : "",
                scpf              : "",
                srg               : "",
                send              : "",
                scomplem          : "",
                sbai              : "",
                scid              : "",
                suf               : "",
                scep              : "",
                semail            : "",
                sprof             : "",
                nconv             : null,
                scart             : "",
                dt_datval         : "",
                ntpind            : "",
                sindpor           : "",
                nestciv           : "",
                nomres            : "",
                scpfres           : "",
                slgpd             : "",
                nropac            : "",
                assinatura        : "",
                listaconvenios    : [],
                tiposdeindicacao  : [],
                estadocivil       : [],
                listaDeEstados    : [],
                listaDeCidades    : [],
                listaCidadeEstado : [],
                cpfExistente      : null,
                consultandoCPF    : false,
                lgpdClinica       : null,
                lgpdClinicaPDF    : null,
                cepExistente      : false,
                gravando          : null,
                message           : "",
                messageStatus     :     "",
                finalizado        : false,

                carregadoCIEC     : null, // c convenio, i indicacao, ec estado civil

                error             : null,
            };
        },

        components:
        {
            Anamnese,
            Assinatura,
            Cadastro,
            Lgpd,
        },

        props:
        {
            user        : Object,
            token       : Boolean,
            clinic      : Object,
            tokenCpf    : String,
            tokenTime   : String,
            resetTokens : Function,
            url         : { type: String, required: true  },
            expAccess   : { type: String, required: false },
        },

        methods:
        {
            updatesnome     ( value ) { this.snome     = value; },
            updatessbrnome  ( value ) { this.ssbrnome  = value; },
            updatedt_datnas ( value ) { this.dt_datnas = value; },
            updatensexo     ( value ) { this.nsexo     = value; },
            updatentpfone1  ( value ) { this.ntpfone1  = value; },
            updatentpfone2  ( value ) { this.ntpfone2  = value; },
            updatentpfone3  ( value ) { this.ntpfone3  = value; },
            updatesfone1    ( value ) { this.sfone1    = value; },
            updatesfone2    ( value ) { this.sfone2    = value; },
            updatesfone3    ( value ) { this.sfone3    = value; },
            updatescpf      ( value ) { this.scpf      = value; },
            updatesrg       ( value ) { this.srg       = value; },
            updatesend      ( value ) { this.send      = value; },
            updatescomplem  ( value ) { this.scomplem  = value; },
            updatesbai      ( value ) { this.sbai      = value; },
            updatescid      ( value ) { this.scid      = value; },
            updatesemail    ( value ) { this.semail    = value; },
            updatesprof     ( value ) { this.sprof     = value; },
            updatentpind    ( value ) { this.ntpind    = value; },
            updatesindpor   ( value ) { this.sindpor   = value; },
            updatenestciv   ( value ) { this.nestciv   = value; },
            updatenomres    ( value ) { this.nomres    = value; },
            updatescpfres   ( value ) { this.scpfres   = value; },
            updateslgpd     ( value ) { this.slgpd     = value; },
            updatenropac    ( value ) { this.nropac    = value; },
            updatescart     ( value ) { this.scart     = value; },
            updatedt_datval ( value ) { this.dt_datval = value; },

            updatelistaconvenios   ( value ) { this.listaconvenios    = value; },
            updatetiposdeindicacao ( value ) { this.tiposdeindicacao  = value; },
            updateestadocivil      ( value ) { this.estadocivil       = value; },
            updatelistaDeEstados   ( value ) { this.listaDeEstados    = value; },
            updatelistaDeCidades   ( value ) { this.listaDeCidades    = value; },
            updatecepExistente     ( value ) { this.cepExistente      = value; },
            salvaAssinatura        ( value ) { this.assinatura        = value; },

            updatenconv( value )
            {
                this.nconv = value;

                if ( this.nconv == 1 )
                {
                    this.scart     = null;
                    this.dt_datval = null;
                }
            },

            updatesuf( value )
            {
                this.suf = value;
                this.atualizarCidades();
            },

            updatescep( value )
            {
                this.scep = value;
                this.recuperarCEP();
            },

            submitFormAnamnese()
            {
                this.gravando = true;

                axios
                .post(
                    `${this.url}/clinica/paciente/anamnese`,
                    {
                        nropac     : this.nropac,
                        scpf       : this.scpf,
                        snome      : this.snome,
                        ssbrnome   : this.ssbrnome,
                        anamnese   : this.anamnese,
                        assinatura : this.assinatura,
                    },
                    this.headers
                )
                .then( response =>
                {
                    this.treatResponseSuccess();
                } )
                .catch( err =>
                {
                    console.log( `recordPatientAnamnese(), ${err.name}: ${err.message}` );

                    this.treatResponseError( err.response.data );
                } );
            },

            inicio()
            {
                this.scpf          = "";
                this.fase          = 0;
                this.cpfExistente  = null;
                this.assinatura    = "";
                this.imageUrl      = null;
                this.imageFile     = null;
                this.imageName     = "";
                this.carregadoCIEC = null;
                this.error         = null;
                this.carregar();
            },
            avancar()
            {
                this.fase += 1;
            },
            voltar()
            {
                if ( this.fase >= 1 )
                    this.fase -= 1;
            },

            recuperarCEP()
            {
                let cepTratado = this.scep.replace( /\D/g, "" );

                axios
                .get( `${this.url}/api/endereco/cep/${cepTratado}` )
                .then( response =>
                {
                    this.atualizarCidades();
                    this.cepExistente = true;
                    this.suf          = response.data.uf;
                    this.scid         = response.data.localidade;
                    this.sbai         = response.data.bairro;
                    this.send         = response.data.logradouro;
                } )
                .catch( err =>
                {
                    console.log( `buscarCEP(), ${err.name}: ${err.message}` );

                    this.cepExistente = false
                } );
            },

            atualizarCidades()
            {
                axios
                .get( `${this.url}/api/endereco/${this.suf}/cidades` )
                .then( response =>
                {
                    this.listaDeCidades = response.data
                } )
                .catch( err =>
                {
                    console.log( `listarCidades(), ${err.name}: ${err.message}` );
                } );
            },
            carregarEstados()
            {
                axios
                .get( `${this.url}/api/endereco/estados/` )
                .then( response =>
                {
                    this.listaDeEstados = response.data
                } )
                .catch( err =>
                {
                    console.log( `listarEstados(), ${err.name}: ${err.message}` );
                } );
            },

            carregarLGPD()
            {
                axios
                .get( `${this.url}/clinica/lgpd/` )
                .then( response =>
                {
                    this.slgpd = response.data
                } )
                .catch( err =>
                {
                    console.log( `retrieveLGPD(), ${err.name}: ${err.message}` );
                } );
            },

            carregar()
            {
                const header =
                {
                    headers : {
                        "Content-Type" : "application/json",
                        "X-CSRF-TOKEN" : window.Laravel.csrfToken,
                    }
                };

                axios
                .get(
                    `${this.url}/clinica/dados/`,
                    header
                )
                .then( response =>
                {
                    this.listaconvenios   = response.data.convenios;
                    this.tiposdeindicacao = response.data.indicacao;
                    this.estadocivil      = response.data.estciv;
                    this.carregadoCIEC    = true;

                    if ( this.tokenCpf )
                        this.recuperarPaciente();
                } )
                .catch( err =>
                {
                    console.log( `getData(), ${err.name}: ${err.message}` );

                    this.carregadoCIEC = false;
                } );
            },

            replaceAll( search, replace )
            {
                if ( replace === undefined )
                    return this.toString();

                return this.split( search ).join( replace );
            },

            formatarData: function( date )
            {
                return moment( date, "YYYY-MM-DD" ).format( "DD/MM/YYYY" );
            },

            formatarDataHora: function()
            {
                return moment().format( "YYMMDDHHmmss" );
            },

            recuperarPaciente()
            {
                let cpfTratado = this.scpf.replace( /\D/g, "" );

                if ( this.scpf.length == 14 )
                {
                    this.consultandoCPF = true;

                    axios
                    .get( `${this.url}/clinica/paciente/${cpfTratado}` )
                    .then( response =>
                    {
                        if ( !response.data && response.data.tx_status )
                        {
                            this.cpfExistente   = false;
                            this.consultandoCPF = false;
                            this.gravando       = false;
                        }

                        this.atualizarCidades();

                        this.snome    = response.data.snome.replace( response.data.ssbrnome,"" ).trimEnd();
                        this.ssbrnome = response.data.ssbrnome;
                        this.nsexo    = response.data.nsexo;
                        this.srg      = response.data.srg;
                        this.sprof    = response.data.sprof;
                        this.semail   = response.data.semail;
                        this.nropac   = response.data.nropac;
                        this.ntpfone1 = response.data.ntpfone1;
                        this.ntpfone2 = response.data.ntpfone2;
                        this.ntpfone3 = response.data.ntpfone3;
                        this.sfone1   = response.data.sfone1;
                        this.sfone2   = response.data.sfone2;
                        this.sfone3   = response.data.sfone3;
                        this.scep     = response.data.scep;
                        this.send     = response.data.send;
                        this.scomplem = response.data.scomplem;
                        this.nomres   = response.data.nomres;
                        this.scpfres  = response.data.scpfres;
                        this.scid     = response.data.scid;
                        this.sbai     = response.data.sbai;
                        this.suf      = response.data.suf;
                        this.nconv    = response.data.nconv;
                        this.scart    = response.data.scart;
                        this.ntpind   = response.data.ntpind;
                        this.sindpor  = response.data.sindpor;
                        this.nestciv  = response.data.nestciv;

                        if ( ( response.data.nropac               ) &&
                            !( response.data.nconv.length   === 0 ) &&
                            !( response.data.ntpind.length  === 0 ) &&
                            !( response.data.nestciv.length === 0 ) )
                        {
                            if ( response.data.dt_datnas )
                                this.dt_datnas = this.formatarData( response.data.dt_datnas );

                            if ( response.data.dt_datval )
                                this.dt_datval = this.formatarData( response.data.dt_datval );

                            axios
                            .get( `${this.url}/clinica/anamnese/${this.nropac}` )
                            .then( response =>
                            {
                                this.anamnese       = response.data;
                                this.cpfExistente   = true;
                                this.consultandoCPF = false;
                                this.gravando       = false;
                            } )
                            .catch( err =>
                            {
                                console.log( `retrieveAnamnese(), ${err.name}: ${err.message}` );

                                this.cpfExistente   = false;
                                this.consultandoCPF = false;
                                this.gravando       = false;
                            } );
                        }
                        else
                        {
                            this.cpfExistente   = false;
                            this.consultandoCPF = false;
                            this.gravando       = false;
                        }
                    } )
                    .catch( err =>
                    {
                        console.log( `retrievePatient(), ${err.name}: ${err.message}` );

                        this.cpfExistente   = false;
                        this.consultandoCPF = false;
                        this.gravando       = false;
                    } );
                }

                if ( this.scpf.length == 0 )
                {
                    this.cpfExistente = null;
                    this.scpf         = "";
                }
            },

            preSubmit()
            {
                if ( this.userOpt == "cadastro" )
                    this.anamnese = null;

                this.gravando  = true;
                this.imageName = `foto_${this.nropac}_${this.formatarDataHora()}.png`;

                const withImg  = true;
                const wcam     = this.$refs.vueFormCadastro.$refs.vueCamFile.camOption;
                const file     = this.$refs.vueFormCadastro.$refs.vueCamFile.fileOption;

                if ( wcam )
                {
                    this.imageUrl = this.$refs.vueFormCadastro.$refs.vueCamFile.photoUrl;

                    this
                    .sendImage( false )
                    .then( response =>
                    {
                        this.submitForm( withImg );
                    } )
                    .catch( err =>
                    {
                        console.log( `recordPhoto(), ${err.name}: ${err.message}` );

                        this.submitForm( !withImg );
                    } );
                }
                else if ( file )
                {
                    this.imageFile = this.$refs.vueFormCadastro.$refs.vueCamFile.fileObj;
                    this.imageUrl  = this.$refs.vueFormCadastro.$refs.vueCamFile.fileUrl;

                    this
                    .sendImage( true )
                    .then( response =>
                    {
                        this.submitForm( withImg );
                    } )
                    .catch( err =>
                    {
                        console.log( `recordPhoto(), ${err.name}: ${err.message}` );

                        this.submitForm( !withImg );
                    } );
                }
                else
                {
                    $( "#modal-img" ).modal( "show" );
                }
            },

            sendImage( isFile )
            {
                let data    = null;
                let content = "";

                if ( isFile )
                {
                    let formData = new FormData();
                    formData.append( "imageFile", this.imageFile );
                    formData.append( "imageName", this.imageName );
                    formData.append( "nropac",    this.nropac    );
                    formData.append( "isFile",    true           );

                    data         = formData;
                    content      = "multipart/form-data";
                }
                else
                {
                    content = "application/json";
                    data    = {
                        imageFile : this.imageUrl,
                        imageName : this.imageName,
                        nropac    : this.nropac,
                        isFile    : false,
                    };
                }

                return axios
                .post(
                    `${this.url}/clinica/paciente/imagem`,
                    data,
                    this.headers
                );
            },

            subWithoutPhoto()
            {
                $( "#modal-img" ).modal( "hide" );
                const withImg = false;
                this.submitForm( withImg );
            },

            submitForm( withImage )
            {
                if ( !withImage )
                    this.gravando = true;

                axios
                .post(
                    `${this.url}/clinica/paciente/cadastro`,
                    {
                        snome      : this.snome,
                        ssbrnome   : this.ssbrnome,
                        dt_datnas  : this.dt_datnas,
                        nsexo      : this.nsexo,
                        ntpfone1   : this.ntpfone1,
                        ntpfone2   : this.ntpfone2,
                        ntpfone3   : this.ntpfone3,
                        sfone1     : this.sfone1,
                        sfone2     : this.sfone2,
                        sfone3     : this.sfone3,
                        anamnese   : this.anamnese,
                        scpf       : this.scpf,
                        srg        : this.srg,
                        send       : this.send,
                        scomplem   : this.scomplem,
                        sbai       : this.sbai,
                        scid       : this.scid,
                        suf        : this.suf,
                        scep       : this.scep,
                        semail     : this.semail,
                        sprof      : this.sprof,
                        nconv      : this.nconv,
                        scart      : this.scart,
                        dt_datval  : this.dt_datval,
                        ntpind     : this.ntpind,
                        sindpor    : this.sindpor,
                        nestciv    : this.nestciv,
                        nropac     : this.nropac,
                        nomres     : this.nomres,
                        scpfres    : this.scpfres,
                        assinatura : this.assinatura,
                        imagename  : ( withImage ) ? this.imageName : "profile.png",
                    },
                    this.headers
                )
                .then( response =>
                {
                    this.treatResponseSuccess();
                } )
                .catch( err =>
                {
                    console.log( `recordPatient(), ${err.name}: ${err.message}` );

                    console.log( err.response );

                    this.treatResponseError( err.response.data );
                } );
            },

            chooseImage()
            {
                $( "#modal-img" ).modal( "hide" );
                this.$refs.vueFormCadastro.$refs.vueCamFile.chooseImage();
            },
            openCamera()
            {
                $( "#modal-img" ).modal( "hide" );
                this.$refs.vueFormCadastro.$refs.vueCamFile.openCamera();
            },

            treatResponseSuccess()
            {
                this.messageStatus = "alert-success";
                this.message       = "Dados foram salvos com sucesso.";
                this.gravando      = false;
                this.error         = false;
                this.fase          = 3;
            },
            treatResponseError( response )
            {
                if ( response.code == "400SFL" )
                    this.setError();
                else if ( response.exception )
                    this.setError( response.message );
                else
                    this.treatResponseSuccess();
            },
            setError( err )
            {
                const message = ( err )
                    ? "Ops! Aconteceu um erro ao gravar os dados. Contate o suporte."
                    : "Ops! Aconteceu um erro ao gravar os dados. Tente novamente.";

                this.error         = true;
                this.gravando      = false;
                this.message       = message;
                this.messageStatus = "alert-warning";
            },
        },

        created()
        {
            if ( this.tokenTime )
            {
                const tkTime = moment( this.tokenTime );
                const now    = moment();
                const diff   = now.diff( tkTime, "seconds" );
                const time   = this.expAccess * 60 * 60;

                // Valida o tempo após o primeiro acesso em segundos (3600s == 60 min)
                if ( diff > time )
                    window.location.href = "nao_autorizado/Link expirado!";
            }
        },

        mounted()
        {
            axios
            .post(
                `${this.url}/easy-log`,
                {
                    route : this.$route.path,
                    code  : 200,
                },
            );

            if ( this.tokenCpf )
            {
                const decode = this.tokenCpf.split( "||" );

                this.scpf    = decode[ 1 ];
                this.userOpt = decode[ 2 ];
            }

            this.carregarEstados();
            this.carregarLGPD();
            this.carregar();
        },
    };
</script>

<style scoped >
    .alert   { display: flex; align-items: center; }
    .alert i { margin-right: 1rem; }

    .img-login { max-width: 300px; text-align: center; }

    .loader { border: 5px solid #F3F3F3; /* Light grey */ border-top: 5px solid #555; /* Blue */ border-radius: 50%; width: 30px; height: 30px; animation: spin 2s linear infinite; display: inline-block; margin-right: 1rem; }

    .alert-warning-ed {
        color: #857b26;
        background-color: #fffbdb;
        border-color: #fffacc;
    }

    @keyframes spin {
        0%   { transform: rotate(0deg);   }
        100% { transform: rotate(360deg); }
    }
</style>
