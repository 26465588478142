<template>

    <section>
        <header class="d-flex align-items-center justify-content-center mt-2rem" style="font-family: 'Arial', sans-serif !important; font-size: 20px;" >
            <b>{{ this.clinic.nome }}</b>
        </header>

        <div class="form container-fluid" >
            <div class="row justify-content-center" >
                <div class="col-12 col-md-8 col-lg-4" v-if="fase === 0" >

                    <div class="card mt-2rem" >
                        <div class="card-header" >
                            <h4 class="ft-1rem mb-0" >Olá!</h4>
                        </div>
                        <div class="card-body" >
                            <p>Para começar, por favor, insira o número do CPF. Vamos consultar o cadastro.</p>

                            <img v-bind:src="'/img/login.png'" class="img-fluid mx-auto my-4 d-block img-login" style="height: 200px;" alt="Informe o CPF para continuar" />

                            <div class="form-row" >
                                <div class="form-group col-md-12 col-12 mb-0" >
                                    <label for="scpf" >Por favor, informe o CPF:</label>
                                    <input v-model="scpf" id="scpf" class="form-control" type="text"
                                        placeholder="ex: 000.000.000-00" v-mask="'###.###.###-##'" required autofocus
                                        v-on:input="recuperarPaciente" v-bind:disabled="this.tokenCpf > 0" />
                                </div>
                            </div>

                            <p class="mt-3 mb-0 text-center" >Defina o período</p>

                            <div class="form-row" >
                                <div class="form-group col-md-12 col-12 my-0" >
                                    <label for="dt_start" >Data inicial: </label>
                                    <input v-model="dtstart" id="dt_start" class="form-control" type="date" v-bind:disabled="this.today" required />
                                </div>
                            </div>
                            <div class="form-row" >
                                <div class="form-group col-md-12 col-12 mb-0 mt-3" >
                                    <label for="dt_end" >Data final: </label>
                                    <input v-model="dtend" id="dt_end" class="form-control" type="date" v-bind:disabled="this.today" required />
                                </div>
                            </div>

                            <!-- INICIO - validação campo CPF -->
                            <div class="carregamento" v-if="consultingCPF == true" >
                                <div class="alert alert-info mt-4 mb-0" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando CPF...</span>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-warning mt-4 mb-0" v-if="existingCPF == false" >
                                <i class="fas fa-exclamation-circle" ></i> CPF não encontrado.
                            </div>
                            <div class="alert alert-success mt-4 mb-0" v-if="existingCPF == true && validatedFields == false && consultingCPF == false" >
                                <i class="fas fa-check-circle" ></i> Preencha o período e clique em Buscar para consultar o histórico.
                            </div>
                            <!-- FIM - validação campo CPF -->

                            <!-- INICIO - validação consulta historico -->
                            <div class="carregamento" v-if="consultingHist == true" >
                                <div class="alert alert-info mt-4 mb-0" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando histórico...</span>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-warning mt-4 mb-0" v-if="existingHist == false" >
                                <i class="fas fa-exclamation-circle" ></i> Nenhum histórico encontrado para este CPF neste período.<br>Por favor retorne e tente novamente.
                            </div>
                            <div class="alert alert-success mt-4 mb-0" v-if="existingHist == true" >
                                <i class="fas fa-check-circle" ></i> Tudo certo!
                                Clique em Próximo para continuar.
                            </div>
                            <!-- FIM - validação consulta historico -->
                        </div>

                        <div class="card-footer" v-if="existingCPF == true && existingHist != true && validatedFields == false" >
                            <div class="float-right" >
                                <button class="btn btn-primary" v-on:click="validateFields" v-bind:disabled="consultingHist == true" >
                                    Buscar
                                </button>
                            </div>
                        </div>

                        <div class="card-footer" v-if="validatedFields == true && existingHist == true" >
                            <div class="float-right" >
                                <button class="btn btn-success" v-on:click="avancar" >
                                    Próximo
                                </button>
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingHist == false" >
                            <div class="float-right" >
                                <button class="btn btn-success" v-on:click="inicio" >
                                    Retornar
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <form v-on:submit.prevent="submitForm" >

                <div id="extraslgpd" class="card mt-2rem" v-if="fase === 1" >
                    <div class="card-header" >
                        <h4 class="ft-1rem mb-0" v-if="this.today" >Procedimentos do dia</h4>
                        <h4 class="ft-1rem mb-0" v-if="!this.today" >Procedimentos do período</h4>
                    </div>
                    <div class="card-body table-responsive p-0 " >
                        <table class="table table-lg table-borderless table-striped table-head-fixed mb-4" >
                            <thead>
                                <tr>
                                    <th class="px-2 text-center item-dt"       >Data</th>
                                    <th class="px-2 text-center item-prest"    >Cirurgião Dentista</th>
                                    <th class="px-2 text-center item-regiao"   >Região</th>
                                    <th class="px-2 text-center item-historic" >Descrição dos procedimentos realizados</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in historic" v-bind:key="i" >
                                    <td class="px-2 text-center"         >{{ item.DT_HIST      }}</td>
                                    <td class="px-2 text-center"         >{{ item.NM_PRESTADOR }}</td>
                                    <td class="px-2 text-center"         >{{ item.NM_REGIAO    }}</td>
                                    <td class="px-2 text-left text-wrap" >{{ item.HISTORICO    }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="assinatura" class="card mt-2rem" v-if="fase === 1" >
                    <div class="card-header" >
                        <h4 class="ft-1rem mb-0" >Assinatura</h4>
                    </div>
                    <div class="card-body" >
                        <p>Para consentir com o termo acima, por favor, faça sua assinatura na área abaixo:</p>
                        <div class="form-row" >
                            <div class="form-group col-md-12 col-12" >
                                <label for>Assinatura</label>

                                <VueSignaturePad width="100%" height="300px" style="border: 1px solid #f0f0f0" ref="signaturePad" class="signaturePad" />

                                <div class="acoes mt-4" >
                                    <button type="button" class="btn btn-outline-warning" v-on:click="undo" >
                                        <i class="fas fa-eraser" ></i> Apagar
                                    </button>
                                    <button type="button" class="btn btn-success" v-on:click="save" >
                                        Confirmar minha assinatura
                                    </button>
                                </div>
                            </div>
                        </div>
                        <p v-if="signature == ''" >
                            Para consentir com o termo, faça a sua assinatura no quadro acima e clique em "Confirmar minha assinatura".
                        </p>
                        <button type="submit" class="btn btn-success btn-block" v-bind:disabled="saving || signature == ''" >
                            Confirmar
                        </button>

                        <div class="alert mt-3 mb-0" :class="messageStatus" v-if="error == true" >
                            <i class="fas fa-exclamation-circle" ></i>
                            {{ message }}
                        </div>

                        <div class="alert alert-info mt-3 mb-0" v-if="saving" >
                            <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                <span class="sr-only" >Loading...</span>
                            </div>
                            Gravando dados...
                        </div>
                    </div>
                </div>
            </form>

            <div class="row justify-content-center" >
                <div class="col-12 col-lg-4" >
                    <div id="resultado" class="card mt-2rem" v-if="fase === 2" >
                        <div class="card-header" >
                            <h4 class="ft-1rem mb-0" >Obrigado, {{ snome }}!</h4>
                        </div>
                        <div class="card-body" >
                            <div class="alert msgGravacao mt-0" v-bind:class="messageStatus" >
                                {{ message }}
                            </div>
                            <button type="button" class="btn btn-success float-right" v-on:click="inicio" >
                                Retornar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>

</template>

<script>
    import axios  from "axios";
    import moment from "moment";

    export default {
        data()
        {
            return {
                headers         : { headers : { "Content-Type" : "application/json", Accept : "*" } },

                fase            : 0,
                snome           : "",
                ssbrnome        : "",
                scpf            : "",
                nropac          : "",
                dtstart         : "",
                dtend           : "",
                signature       : "",
                historic        : null,

                existingCPF     : null,
                consultingCPF   : false,

                existingHist    : null,
                consultingHist  : false,

                validatedFields : false,

                saving          : null,
                message         : "",
                messageStatus   : "",

                error           : null,
            };
        },

        props:
        {
            today: Boolean,
            clinic: Object,
            tokenCpf: String,
            url: { type: String, required: true }
        },

        methods:
        {
            inicio()
            {
                this.fase            = 0;
                this.snome           = "";
                this.ssbrnome        = "";
                this.scpf            = "";
                this.signature       = "";
                this.existingCPF     = null;
                this.consultingCPF   = false;
                this.existingHist    = null;
                this.consultingHist  = false;
                this.validatedFields = false;
                this.dtstart         = this.dtend = moment().format("YYYY-MM-DD");
                this.error           = null;
            },
            avancar()
            {
                this.fase += 1;
            },

            undo()
            {
                this.$refs.signaturePad.clearSignature();
                this.signature = "";
            },
            save()
            {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

                if ( !isEmpty )
                {
                    let canvas     = document.querySelector( "canvas" );
                    this.signature = canvas.toDataURL();
                }
                else
                {
                    alert( "A assinatura está em branco." );
                }
            },

            validateFields()
            {
                let aux   = null;
                let start = moment( this.dtstart );
                let end   = moment( this.dtend   );

                if ( end.diff( start, "days" ) < 0 )
                {
                    aux   = this.dtstart;
                    start = this.dtend;
                    end   = aux;
                }

                this.validatedFields = true;

                if ( this.existingCPF )
                    this.recuperarHistorico();
            },

            recuperarPaciente()
            {
                let treatedCPF = this.scpf.replace(/\D/g, "");

                if ( this.scpf.length == 14 )
                {
                    this.consultingCPF = true;

                    axios
                    .get( `${this.url}/clinica/paciente/${treatedCPF}` )
                    .then( response =>
                    {
                        let data = response.data;

                        if ( data.nropac &&
                            !( data.sanot.length   === 0 ) &&
                            !( data.nconv.length   === 0 ) &&
                            !( data.ntpind.length  === 0 ) &&
                            !( data.nestciv.length === 0 ) )
                        {
                            this.existingCPF = true;
                            this.nropac      = data.nropac;
                            this.snome       = data.snome.replace( data.ssbrnome, "" ).trimEnd();
                            this.ssbrnome    = data.ssbrnome;

                            if ( this.today )
                                this.validateFields();
                        }
                        else
                        {
                            this.existingCPF = false;
                        }

                        this.consultingCPF = false;

                        if ( this.tokenCpf && this.tokenCpf.length > 0 )
                            this.avancar();
                    } )
                    .catch( err =>
                    {
                        console.log( `retrievePatient(), ${err.name}: ${err.message}` );

                        this.existingCPF   = false;
                        this.consultingCPF = false;
                    } );
                }

                if ( this.scpf.length == 0 )
                {
                    this.consultingCPF = false;
                    this.existingCPF   = null;
                    this.scpf          = "";
                }
            },

            recuperarHistorico()
            {
                this.existingHist   = null;
                this.consultingHist = true;

                if ( this.today )
                    this.dtstart = this.dtend = moment().format( "YYYY-MM-DD" );

                axios
                .post(
                    `${this.url}/clinica/paciente/historico`,
                    {
                        nropac  : this.nropac,
                        dtstart : this.dtstart,
                        dtend   : this.dtend,
                    },
                    this.headers
                )
                .then( response =>
                {
                    if ( response.data.data )
                    {
                        this.historic       = response.data.data;
                        this.existingHist   = true;
                        this.consultingHist = false;
                    }
                    else
                    {
                        this.historic       = null;
                        this.existingHist   = false;
                        this.consultingHist = false;
                    }
                } )
                .catch( err =>
                {
                    console.log( `retrieveHistory(), ${err.name}: ${err.message}` );

                    this.historic       = null;
                    this.existingHist   = false;
                    this.consultingHist = false;
                } );
            },

            submitForm()
            {
                this.saving = true;

                axios
                .post(
                    `${this.url}/clinica/paciente/documento`,
                    {
                        docType   : "historic",
                        sdoc      : "Historico",
                        snome     : this.snome,
                        ssbrnome  : this.ssbrnome,
                        scpf      : this.scpf,
                        nropac    : this.nropac,
                        historic  : this.historic,
                        signature : this.signature,
                    },
                    this.headers
                )
                .then( response =>
                {
                    this.treatResponseSuccess();
                } )
                .catch( err =>
                {
                    console.log( `recordDocument(), ${err.name}: ${err.message}` );

                    this.treatResponseError( err.response.data );
                } );
            },

            treatResponseSuccess()
            {
                this.messageStatus = "alert-success";
                this.message       = "Documento enviado com sucesso.";
                this.saving        = false;
                this.fase          = 2;
            },
            treatResponseError( response )
            {
                if ( response.code == "400SFL" )
                    this.setError();
                else if ( response.exception )
                    this.setError( response.message );
                else
                    this.treatResponseSuccess();
            },
            setError( err )
            {
                const message = ( err )
                    ? "Ops! Aconteceu um erro ao gravar os dados. Contate o suporte."
                    : "Ops! Aconteceu um erro ao gravar os dados. Tente novamente.";

                this.error         = true;
                this.gravando      = false;
                this.message       = message;
                this.messageStatus = "alert-warning";
            },
        },

        created()
        {
            this.dtstart = this.dtend = moment().format( "YYYY-MM-DD" );
        },

        mounted()
        {
            axios
            .post(
                `${this.url}/easy-log`,
                {
                    route : this.$route.path,
                    code  : 200,
                },
            );

            if ( this.tokenCpf && this.tokenCpf.length > 0 )
            {
                this.scpf = this.tokenCpf.replace( /(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4" );
                this.recuperarPaciente();
            }
        },

        watch:
        {
            'today': {
                immediate : true,
                handler   : function( newVal, oldVal )
                {
                    this.inicio();
                }
            }
        }
    }
</script>

<style scoped >
    #docRtf {
        background: #e9ecef;
        border-radius: 1.5rem;
        border: 1px solid #cad1d7;
    }

    .text-center.item-dt,
    .text-center.item-prest {
        min-width: 100px !important;
    }
    .text-center.item-regiao {
        min-width: 60px !important;
    }
    .text-center.item-historic {
        min-width: 300px !important;
    }

    .alert-warning-ed {
        color: #857b26;
        background-color: #fffbdb;
        border-color: #fffacc;
    }
</style>
